import React from "react";
import Header from "./Home/Header";
import Blog from "./Home/Blog";
import Services from "./Home/Services";
import Achievement from "./Home/Achievement";
import Testimonial from "./Home/Testimonial";
import Youtube from "./Home/Youtube";


function HomePage() {
  return (
    <div><Header/>
    <Blog/> 
    <Services/>
    <Achievement/>
    <Testimonial/>
    <Youtube/>
    </div>
  );
}

export default HomePage;
