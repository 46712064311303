import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ blog }) => {
  return (
    <div className=" p-4 rounded-md   transition-transform transform hover:scale-105">
      <img
        src={blog.image}
        alt={blog.headline}
        className="w-full h-48 object-cover mb-4  "
      />
      <div className="mb-2">
        {blog.tags.map((tag) => (
          <span
            key={tag}
            className="bg-red-100 px-2 py-1 text-sm  mr-2"
          >
            {tag}
          </span>
        ))}
      </div>
      <h2 className="text-xl font-bold mb-2 text-gray-800">{blog.headline}</h2>
      <Link
        to={`/blog/${blog.id}`}
        className="text-gray-600 hover:underline inline-block"
      >
        Read more
      </Link>
    </div>
  );
};

export default BlogCard;
