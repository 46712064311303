// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Use Routes instead of Route
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import BlogPage from './components/BlogPage';
import BlogList from './components/Blogs/BlogList';
import IndividualBlog from './components/Blogs/IndividualBlog';
import ContactPage from './components/ContactPage';
import Admin from './components/Contact/Admin';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blogs" element={<BlogPage />} />
           <Route path="/bloglist" element={<BlogList />} />
            <Route path="/blog/:id" element={<IndividualBlog />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/admin" element={<Admin />} />
          
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
