import React from "react";
import EventCard from "./EventCard"; // Adjust the path based on your project structure

function Event() {
  const featuredEvents = [
    {
      title: "Honored with the Brightest Beginner Award 2024 at Aethereus!",
      description:
        "I am thrilled to share that I have been awarded the Brightest Beginner Award for 2024 at Aethereus, recognizing my performance during my one-year college internship.This recognition reflects the dedication and hard work I put into my role, and I am incredibly grateful for the opportunity to learn and grow alongside such a talented team. My internship experience has been invaluable, leading to my new role as a full-time Salesforce Developer at Aethereus.",
      color: "text-indigo-600",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212202&authkey=%21ADuUeA01vae8ZWs&height=1066&width=800",
    },
    {
      title: "Zerodha Varsity Certification",
      description:
        "I have recently completed an invaluable learning experience with Zerodha, one of the leading stock brokers in the Indian market. Through their renowned Varsity platform, I delved into topics ranging from the stock market intricacies to mutual funds and personal finance. and I have successfully passed their Protocored certification exam! 🎓 While its just a small step towards my goals, its enough to keep me motivated and hungry for more knowledge. 💡",
      color: "text-indigo-600",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212196&authkey=%21AINCimZbM2zPETI&width=1194&height=892",
    },
    {
      title: "Thoughtful Argument debate competition organized by SPADE LPU",
      description:
        "This event holds a special place in my heart as it marks my personal triumph over social anxiety. With my team's unwavering support and my determination, I delivered a decent performance.\n\nThe competition was intense, and all the teams showcased their best. However, our well-researched arguments and convincing delivery, thanks to our team effort, helped us stand out.",
      color: "text-indigo-600",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212188&authkey=%21AO8PiL9WEFXldsc&width=720&height=564",
    },
  ];

  return (
    <section className="w-full mt-8 container mx-auto">
      <div className="mb-8">
        <h2 className="text-2xl text-center font-bold mb-4">Featured Events</h2>
        {featuredEvents.map((event, index) => (
          <EventCard
            key={index}
            image={event.image}
            title={event.title}
            description={event.description}
            color={event.color}
          />
        ))}
      </div>
    </section>
  );
}

export default Event;
