import React, { useState } from "react";
import BlogCard from "./BlogCard";
import blogData from "./blogData";

const BlogList = () => {
  const [filteredBlogs, setFilteredBlogs] = useState(blogData);
  const [sortBy, setSortBy] = useState("date"); // 'date', 'alphabet'

  const handleFilter = (tag) => {
    const filtered = blogData.filter((blog) => blog.tags.includes(tag));
    setFilteredBlogs(filtered);
  };

  const handleSort = (type) => {
    let sortedBlogs = [...filteredBlogs];

    if (type === "alphabet") {
      sortedBlogs = sortedBlogs.sort((a, b) =>
        a.headline.localeCompare(b.headline)
      );
    } else if (type === "date") {
      sortedBlogs = sortedBlogs.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    setFilteredBlogs(sortedBlogs);
    setSortBy(type);
  };

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-4xl font-bold mb-4 text-gray-800">All Blogs</h1>
      {/* Filter and Sorting UI */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4 bg-red-50 p-4">
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
          <button
            onClick={() => handleSort("alphabet")}
            className={`hover:text-gray-300 transition duration-300 ${
              sortBy === "alphabet" ? "font-bold" : ""
            }`}
          >
            Sort by Alphabet
          </button>
          <button
            onClick={() => handleSort("date")}
            className={`hover:text-gray-300 transition duration-300 ${
              sortBy === "date" ? "font-bold" : ""
            }`}
          >
            Sort by Date
          </button>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-4 md:mt-0">
          <span className="mr-2 mb-2 md:mb-0 font-semibold">
            Filter by Tag:
          </span>
          {Array.from(new Set(blogData.flatMap((blog) => blog.tags))).map(
            (tag) => (
              <button
                key={tag}
                onClick={() => handleFilter(tag)}
                className="hover:text-gray-300 transition duration-300 mb-2 md:mb-0 md:mr-2 font-medium"
              >
                {tag}
              </button>
            )
          )}
          <button
            onClick={() => setFilteredBlogs(blogData)}
            className={`hover:text-gray-300 transition duration-300 font-medium ${
              sortBy === "all" ? "font-bold" : ""
            }`}
          >
            All
          </button>
        </div>
      </div>
      {/* Blogs */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {filteredBlogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogList;
