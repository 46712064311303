import svgContent from "../svgContent";

import { TypeAnimation } from "react-type-animation";
function Header() {

  return (
    <div className=" bg-transparent w-screen flex  p-10">
      <div
        className="absolute inset-0 w-screen   "
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
      {/* Image on top, Text/Button at the bottom */}
      <div className="md:flex w-full  md:p-10 z-10">
        {/* Image on left (on larger screens) */}
        <div className="  md:order-2 w-full flex  justify-center ">
          <img
            src="https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212178&authkey=%21AGySt8Qv13plrTk&width=660"
            alt="Your Image"
            className="  border-slate-900 w-52  rounded-full border-4  md:w-96"
          />
        </div>

        {/* Text/Button on right (on larger screens) */}
        <div className=" md:order-1">
          <div className="md:text-left text-center pt-5 md:pl-10">
            <TypeAnimation
              className="text-4xl font-bold m-1"
              sequence={[
                "I'm A...",
                1000,
                "A Human",
                1000,
                "A Explorer",
                1000,
                "A Developer",
                1000,
                "A Innovator",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
            />
            <p className="text-base  leading-loose">
              Welcome to my journey! B.Tech CSE Graduate, specialized in ML/AI
              via UpGrad. Salesforce Developer at Aethereus,Zerodha certified in
              Trading Analysis, and with hands-on experience in MERN Stack
              Development. Join me in the dynamic world of tech, innovation, and
              endless possibilities.
              <br></br>
              <span className="text-slate-900 hidden md:visible">
                #TechInnovator #FullStackDeveloper #SalesforceDevelope
                #DataScience
              </span>
            </p>

            <button
              onClick={() => {
                // Handle button click, e.g., redirect to the blog page
                window.location.href = "/about"; // Replace with the actual link to your blog
              }}
              className="bg-slate-900 text-white  py-2 px-4 mt-2"
            >
             Know More...
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;