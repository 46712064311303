function Achievement() {
  const projects = [
    {
      id: "1",
      name: "Doctorshood",
      description: "Laravel, React, MySQL, PHP",
      githubLink: "https://github.com/niteshsinwar/Laravel-website",
    },
    {
      id: "2",
      name: "Blog Site",
      description: "Node, React, MongoDB",
      githubLink: "https://github.com/niteshsinwar/blogging-site-server",
    },
    {
      id: "3",
      name: "Social Media App",
      description: "Firebase, Kotlin, Android Studio",
      githubLink: "https://github.com/niteshsinwar/Social-media-app",
    },
  ];

  const commonSvg = (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width="25px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <line
          x1="10.8492"
          y1="13.0606"
          x2="19.435"
          y2="4.47485"
          stroke="#333333"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></line>{" "}
        <path
          d="M19.7886 4.12134L20.1421 8.01042"
          stroke="#333333"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
        <path
          d="M19.7886 4.12134L15.8995 3.76778"
          stroke="#333333"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
        <path
          d="M18 13.1465V17.6465C18 19.3033 16.6569 20.6465 15 20.6465H6C4.34315 20.6465 3 19.3033 3 17.6465V8.64648C3 6.98963 4.34315 5.64648 6 5.64648H10.5"
          stroke="#333333"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );

  const navigateToRepo = (repoUrl) => {
    window.location.href = repoUrl;
  };

  return (
    <div className="md:my-24">
      <h1 className="text-2xl font-bold md:my-24 text-center">Projects</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white p-6 border-2 border-black shadow cursor-pointer"
            onClick={() => navigateToRepo(project.githubLink)}
          >
            <h3 className="text-xl font-bold mb-4">
              {commonSvg}
              {project.name}
            </h3>
            <p className="text-gray-600">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Achievement;
