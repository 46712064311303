import React from "react";
import { collection, addDoc } from "firebase/firestore"; 
import {db} from './Contact/firebase';
import { useState } from 'react';
import svgContent from "./svgContent";
function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function takeName(event){
    setName(event.target.value);
  }
  function takeEmail(event){
      setEmail(event.target.value);
    }
    function takeMessage(event){
      setMessage(event.target.value);
    }
    function handleSubmit(){
      const data = {
          name: name,
          email:email,
          message:message}
          setName("");
          setEmail("");
          setMessage("");
          console.log(data);
     try{
     const docRef= addDoc(collection(db, "feedback"), {    
          name:data.name,
          email: data.email,
          message: data.message
        });  
      console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.log("Error adding document: ", e);
          }
setTimeout(() => {
  window.location.reload();
}, 3000);
     };
     
     

    return (
    
           <div className="container mx-auto mt-8 mb-4">
                    <div class="absolute top-0 left-0 w-full Z-10 h-screen md:h-full" dangerouslySetInnerHTML={{ __html: svgContent }} />
      <div className="lg:flex lg:justify-between relative Z-50">
        {/* Left Section (for laptop screen) */}
        <div className="lg:w-1/2  hidden lg:block p-32">
          <div className="mb-8">
            <h2 className="text-4xl font-bold mb-4 text-gray-800">Contact Information</h2>
            <p className="mb-2 text-lg text-gray-600">Mobile: +1234567890</p>
            <p className="mb-2 text-lg text-gray-600">Email: example@example.com</p>
            <p className="mb-2 text-lg text-gray-600">Address: 123 Main St, City</p>
          </div>
        </div>

        {/* Right Section (for laptop screen) */}
        <div className="lg:w-1/2  bg-red-50 opacity-100">
          <div className=" p-8  ">
            <div className="max-w-md">
              <h2 className="text-4xl font-bold mb-6 text-gray-800">Contact Form</h2>
              <div className="mb-6">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  onChange={takeName}
                  className="mt-1 p-3 w-full border-b-2 border-0 bg-red-50 border-black focus:outline-none focus:border-blue-500"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={takeEmail}
                  className="mt-1 p-3 w-full border-b-2 border-0 bg-red-50 border-black focus:outline-none focus:border-blue-500"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  onChange={takeMessage}
                  rows="4"
                  className="mt-1 p-3 w-full border-b-2 border-0 bg-red-50 border-black focus:outline-none focus:border-blue-500"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-slate-900 text-white p-3  px-10 transition duration-300"
              >
                Send message
              </button>
            </div>
          </div>
        </div>

        
      </div>
    </div>
    );
  }
  
  export default ContactPage;