import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider ,getAuth} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCIBgaa-Ja8ecMR3EhL041hhKBrhD5Pkgg",
    authDomain: "nitesh-portfolio-website.firebaseapp.com",
    projectId: "nitesh-portfolio-website",
    storageBucket: "nitesh-portfolio-website.appspot.com",
    messagingSenderId: "545253299611",
    appId: "1:545253299611:web:64c8f5223e08a684ecbd3e",
    measurementId: "G-9RMX7D0HVZ"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export const auth = getAuth(app);
export const provider =new GoogleAuthProvider();



