import React from "react";
import PostCard from "./PostCard"; // Import your PostCard component

const Post = () => {
  // Example data for posts (replace this with your actual data)
  const posts = [
    {
      id: 1,
      title: "GDSC Core Team Member",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212183&authkey=%21ALuku-tPBUBFSak&width=1920&height=1080",
      content: "Selected as part of GDSC-LPU Core Team Member ",
    },
    {
      id: 1,
      title: "Google Solution Challenge",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212182&authkey=%21ANNYsDxJBng63ag&width=1920&height=1080",
      content:
        "Succesfully submitted Project for within a month with proper functnality",
    },
    {
      id: 1,
      title: "GFG DSA self paced",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212184&authkey=%21ALrX9YS-LvR6PwE&width=1920&height=1080",
      content:
        "Completed GFG DSA Self paced course as part of College summer training program ",
    },
    {
      id: 1,
      title: "JAVA Level 2",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212189&authkey=%21ANrF9sz8-XDHCj4&width=800&height=565",
      content: "Passed JAVA Level 2 exam",
    },

    // Add more posts as needed
  ];

  return (
    <div className="bg-white opacity-100  relative z-index-50 py-20">
      <h2 className="text-2xl text-center font-bold mb-4">Posts</h2>
      <div className="bg-white opacity-100 relative z-index-50 overflow-x-auto container mx-auto flex space-x-4 p-4">
        {posts.map((post) => (
          <PostCard key={post.id} title={post.title} content={post.content}  image={post.image}/>
        ))}
      </div>
    </div>
  );
};

export default Post;
