import { FaYoutube } from "react-icons/fa";

function Youtube() {
  // Dummy video data
  const videos = [
    {
      id: "video-id-1",
      title: "Omnistudio Docgen Document Generation",
      url: "https://www.youtube.com/embed/aNQ2MKPwL3c?si=hSolLhPWfvRxos3K",
      description:
        "Dive into the Salesforce Omnistudio DocGen universe with me! 🌟 Discover the secrets behind document templates, dataraptors, omniscripts, and witness the magic happen with the Generate Doc button.",
    },
    {
      id: "video-id-2",
      title: "Blogging website Demo",
      url: "https://www.youtube.com/embed/Tm1Wp68B7xg?si=QhSQTWwNDAsx_BCq",
      description:
        "ndia's Largest Hackathon Tech-A-Thon Organized by Techathon organization of Lovely Professional university in sponsorship with INeuron.  ",
    },
 
  ];

  return (
    <div>
      <div className="bg-white p-6 md:my-20 text-center">
        <div className="flex items-center justify-center mb-4">
          <FaYoutube className="text-red-500 text-2xl mr-2" />
          <h2 className="text-2xl font-bold">Featured Videos</h2>
        </div>
        <p className="text-gray-600">
          Explore our collection of creative and inspiring videos that will
          captivate your mind.
        </p>
        {/* Add more creative elements as needed */}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 text-center lg:grid-cols-3 gap-8">
        {videos.map((video) => (
          <div key={video.id} className="bg-white p-6 ">
            <div className="relative aspect-w-16 aspect-h-9">
              <iframe
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="w-full h-full rounded-md"
              ></iframe>
            </div>
            <div className="mt-4">
              <h3 className="text-xl font-bold  mb-2">
                {video.title}
              </h3>
              <p className="text-gray-700">{video.description}</p>
            </div>
          </div>
        ))}
      </div>
     
    </div>
  );
}

export default Youtube;
