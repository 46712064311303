import React from 'react';
import BlogCard from '../Blogs/BlogCard';
import blogData from '../Blogs/blogData';
function Blog() {
      const featuredBlogs = blogData.filter((blog) => blog.featured);
  return (
    <div className="bg-white opacity-100 relative z-index-50 ">
      <div className="bg-white opacity-100 relative z-index-50 container mx-auto">
        {/* Blog Heading and Button Section */}
        <div className="md:flex items-center text-center justify-between md:mt-0  mt-40 mb-8 md:px-20 w-full">
          <h2 className="text-2xl md:mt-20 md:ml-20 font-bold mb-4">
            Latest Blog Posts
          </h2>
          <button
            className="bg-gray-900 md:mt-20 text-white md:py-2 py-2 px-3 md:mx-0  md:px-4  hover:bg-gray-800 transition duration-300"
            onClick={() => {
              // Handle button click, e.g., redirect to the blog page
              window.location.href = "/blogList"; // Replace with the actual link to your blog
            }}
          >
            View All Posts
          </button>
        </div>

        {/* Blog Introduction Paragraph */}
        <p className="text-center text-gray-600 mb-8">
          Checkout my blogs! Explore the latest insights, tips, and news in
          our industry.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {featuredBlogs.map((blog) => (
            <BlogCard key={blog.id} blog={blog} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
