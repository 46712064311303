import React from 'react';
import { useParams } from 'react-router-dom';
import blogData from './blogData';

const IndividualBlog = () => {
  const { id } = useParams();
  const blogId = parseInt(id, 10);
  const blog = blogData.find((blog) => blog.id === blogId);

  if (!blog) {
    return <div className="container mx-auto mt-8 text-gray-800">Blog not found</div>;
  }

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-4xl font-bold mb-4 text-gray-800">{blog.headline}</h1>
      <img
        src={blog.image}
        alt={blog.headline}
        className="w-full h-64 object-cover mb-4  shadow-md"
      />
      <div className="mb-2">
        {blog.tags.map((tag) => (
          <span
            key={tag}
            className="bg-gray-200 text-gray-700 px-2 py-1 text-sm  mr-2"
          >
            {tag}
          </span>
        ))}
      </div>
      <p className="text-gray-700">{blog.content}</p>
    </div>
  );
};

export default IndividualBlog;
