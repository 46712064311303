// blogData.js

const blogData = [
  {
    id: 1,
    image: "/img/blog/1.png",
    tags: ["Salesforce", "Apex"],
    headline: "Unleashing the Power of Apex: Beyond the Basics",
    content: (
      <div>
        <p>
          Elevate your Salesforce development skills by exploring advanced
          concepts and techniques in Apex programming.
        </p>
        <br />
        <p>
          Dive deep into topics like asynchronous Apex, bulk processing, and
          governor limits to build efficient and scalable solutions.
        </p>
        <br />
        <p>
          Whether you're a seasoned developer or a curious beginner, this blog
          unlocks the secrets to taking your Apex skills to the next level.
        </p>
      </div>
    ),
    featured: true,
  },
  {
    id: 2,
    image: "/img/blog/2.png",
    tags: ["Salesforce", "LWC"],
    headline:
      "Crafting Immersive User Experiences with LWC: A Design Perspective",
    content: (
      <div>
        <p>
          Explore the intersection of design and development in Salesforce as we
          unravel the art of creating visually stunning Lightning Web Components
          (LWC).
        </p>
        <br />
        <p>
          Learn how to leverage design principles, CSS tricks, and LWC features
          to build user interfaces that not only function flawlessly but also
          captivate your users.
        </p>
        <br />
        <p>
          Elevate your Salesforce applications with this guide to crafting
          immersive and engaging user experiences using Lightning Web
          Components.
        </p>
      </div>
    ),
    featured: true,
  },
  {
    id: 3,
    image: "/img/blog/3.png",
    tags: ["Salesforce", "Integration", "Apex"],
    headline:
      "Revolutionizing Business Processes: A Guide to Salesforce Integration Patterns",
    content: (
      <div>
        <p>
          Navigate the complex landscape of Salesforce integration with our
          comprehensive guide to understanding and implementing integration
          patterns.
        </p>
        <br />
        <p>
          From point-to-point integrations to event-driven architectures,
          discover the right approach for your business needs.
        </p>
        <br />
        <p>
          Uncover best practices, common pitfalls, and real-world examples to
          master the art of seamlessly connecting Salesforce with external
          systems.
        </p>
      </div>
    ),
    featured: true,
  },
  {
    id: 4,
    image: "/img/blog/4.png",
    tags: ["Salesforce", "Lifecycle", "Integration"],
    headline:
      "Effortless Development: Streamlining Your Salesforce Development Lifecycle",
    content: (
      <div>
        <p>
          Optimize your Salesforce development process by implementing best
          practices throughout the entire development lifecycle.
        </p>
        <br />
        <p>
          From version control strategies to continuous integration and
          deployment (CI/CD), discover ways to enhance collaboration and deliver
          high-quality solutions faster.
        </p>
        <br />
        <p>
          Revolutionize your development workflow and elevate your team's
          efficiency with this guide to an effortless Salesforce development
          lifecycle.
        </p>
      </div>
    ),
    featured: false,
  },
  {
    id: 5,
    image: "/img/blog/5.png",
    tags: ["Salesforce", "Integration", "AI"],
    headline:
      "Unifying Intelligence: Integrating Artificial Intelligence with Salesforce",
    content: (
      <div>
        <p>
          Explore the future of Salesforce by delving into the seamless
          integration of Artificial Intelligence (AI) capabilities.
        </p>
        <br />
        <p>
          Learn how to leverage AI technologies, such as Salesforce Einstein, to
          enhance decision-making, automate processes, and gain valuable
          insights from your data.
        </p>
        <br />
        <p>
          Unlock the potential of AI integration and propel your Salesforce
          applications into the era of intelligent business solutions.
        </p>
      </div>
    ),
    featured: true,
  },
  {
    id: 6,
    image: "/img/blog/6.png",
    tags: ["Salesforce", "Community Cloud"],
    headline:
      "Building Engaging Communities: A Deep Dive into Salesforce Community Cloud",
    content: (
      <div>
        <p>
          Transform the way you connect with your audience by harnessing the
          power of Salesforce Community Cloud.
        </p>
        <br />
        <p>
          Explore the features and customization options that Community Cloud
          offers to create vibrant and interactive online communities.
        </p>
        <br />
        <p>
          From branding to user engagement strategies, embark on a journey to
          build compelling communities that foster collaboration and drive
          business success.
        </p>
      </div>
    ),
    featured: false,
  },
];

export default blogData;
