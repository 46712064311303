import React from "react";

const EventCard = ({ image,title, description, color }) => {
  return (
    <div className={`event-container bg-red-50 p-4  mt-4 ${color}`}>
      <div className="md:flex items-center">
        <div className="w-full md:w-1/3 md:mr-4">
          <img
            src={image}
            alt={title}
            className="w-full h-64  "
          />
        </div>
        <div className="w-full md:w-2/3 md:ml-4">
          <h3 className="text-2xl font-bold text-gray-800">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
