import React from "react";

const PostCard = ({ title, content ,image}) => {
  return (
    <div className="flex-shrink-0 md:w-1/3 w-1/2 bg-red-50 p-4  ">
      <div className="w-full  md:mr-4">
        <img
          src={image}
          alt={title}
          className="w-full lg:h-52  "
        />
      </div>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-gray-700">{content}</p>
    </div>
  );
};

export default PostCard;
