import React, { useState } from 'react';

const testimonialsData = [
  {
    id: 1,
    text: "During his internship, Nitesh demonstrated remarkable Salesforce skills. His expertise in configuration, integration, Apex, flows, triggers, and Lightning Web Components (LWCs) stood out. Despite being a fresher, Nitesh quickly grasped complex concepts and consistently delivered high-quality solutions. His proactive problem-solving approach, strong teamwork skills, and dedication make him a valuable asset. I highly recommend Nitesh for his outstanding performance and potential in Salesforce development.",
    clientName: "Mrs Vijita Dharewa | Salesforce Tech Lead",
    clientImage:
      "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212203&authkey=%21AL56qZOO5SYqVmk&width=800&height=800", // Replace with the actual image URL
    linkedinUrl: "https://www.linkedin.com/in/vijita-dharewa-24a179140/",
  },
  {
    id: 1,
    text: "Nitesh Sinwar consistently showcases outstanding teamwork, vision, and technical proficiency in our collaborations. His knack for project execution, backed by strong logic and time management, ensures exceptional results even in challenging scenarios. Nitesh's backend coding skills, adaptability, and quick grasp of complex technologies are remarkable. His commitment to excellence and teamwork makes him a valuable asset to any organization. I wholeheartedly recommend Nitesh for his exceptional qualities and invaluable contributions to any team or project.",
    clientName: "Mr Suman Sourabh | UI/UX Designer",
    clientImage:
      "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212204&authkey=%21AJexcG4OJXAL4dA&width=100&height=100", // Replace with the actual image URL
    linkedinUrl: "https://www.linkedin.com/in/sumansourabh9155/",
  },
];

function Testimonial() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handlePrevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev === 0 ? testimonialsData.length - 1 : prev - 1));
  };

  const handleNextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev === testimonialsData.length - 1 ? 0 : prev + 1));
  };

  return (
    
    <div  className="text-center h- py-16 bg-red-50 relative">
      <h2 className="text-2xl font-bold mb-12 ">What Others Say</h2>
     <div className="absolute left-0 top-1/2 transform z-10 -translate-y-1/2 cursor-pointer" onClick={handlePrevTestimonial}>
          {/* Left Arrow Icon (You can replace this with your custom arrow icon) */}
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" transform="matrix(-1 0 0 1 60 0)" fill="white" />
            <path d="M18.2931 30.707C17.9025 30.3165 17.9025 29.6833 18.2931 29.2928L24.6571 22.9289C25.0476 22.5383 25.6808 22.5383 26.0713 22.9289C26.4618 23.3194 26.4618 23.9526 26.0713 24.3431L20.4144 29.9999L26.0712 35.6568C26.4617 36.0473 26.4617 36.6805 26.0712 37.071C25.6807 37.4615 25.0475 37.4615 24.657 37.071L18.2931 30.707ZM41 31L19.0002 30.9999L19.0002 28.9999L41 29L41 31Z" fill="black" />
          </svg>
        </div>

        <div className="absolute right-0 top-1/2 transform z-10 -translate-y-1/2 cursor-pointer" onClick={handleNextTestimonial}>
          {/* Right Arrow Icon (You can replace this with your custom arrow icon) */}
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="white" />
            <path d="M41.7069 30.707C42.0975 30.3165 42.0975 29.6833 41.7069 29.2928L35.3429 22.9289C34.9524 22.5383 34.3192 22.5383 33.9287 22.9289C33.5382 23.3194 33.5382 23.9526 33.9287 24.3431L39.5856 29.9999L33.9288 35.6568C33.5383 36.0473 33.5383 36.6805 33.9288 37.071C34.3193 37.4615 34.9525 37.4615 35.343 37.071L41.7069 30.707ZM19 31L40.9998 30.9999L40.9998 28.9999L19 29L19 31Z" fill="black" />
          </svg>
        </div>
      <div className="max-w-md mx-auto overflow-hidden  relative">
   

      <div className="flex flex-col items-center z-0 justify-center">
  <div className="p-4 rounded-full transition-transform transform ease-in-out duration-300 hover:scale-105">
    <p className="text-sm mb-4">{testimonialsData[currentTestimonial].text}</p>
    <div className="flex flex-col items-center justify-center mb-4">
      <img
        src={testimonialsData[currentTestimonial].clientImage}
        alt={testimonialsData[currentTestimonial].clientName}
        className="w-12 h-12 rounded-full mb-2"
      />
      <div className="text-center mt-2">
        <p className="text-gray-600">{testimonialsData[currentTestimonial].clientName}</p>
        <a
          href={testimonialsData[currentTestimonial].linkedinUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          View on LinkedIn
        </a>
      </div>
    </div>
  </div>
</div>


      </div>
    </div>
  );
}

export default Testimonial;
