import svgContent from "../svgContent";

const jobsData = [
  {
    company: "Aethereus Consulting",
    logo: "https://aethereus.com/wp-content/uploads/2021/01/logo-square.png",
    position: "Salesforce Developer",
    startDate: "July 2023",
    endDate: "Present",
    description:
      "Drive Salesforce excellence with expertise in Apex programming and Lightning Web Components. Streamline processes and elevate user experiences through seamless integrations. ",
  },

  // Add more job entries as needed
];
function Header(){
    return (
      <header className=" bg-transparent w-screen flex overflow-hidden px-5  md:p-10">
        <div
          className="absolute inset-0 w-screen h-screen   "
          dangerouslySetInnerHTML={{ __html: svgContent }}
        />

        <div className="md:flex w-full z-10 md:p-10">
          {/* Text/Button on right (on larger screens) */}
          <div className="md:w-1/2 flex justify-center md:justify-left md:pl-10 md:pt-10">
            <div className="">
              <h2 className="text-4xl font-bold m-2 text-center md:text-left">
                About Me
              </h2>
              <section class="leading-relaxed mb-6">
                <p class="mb-4">
                  <span class="font-bold italic">Hello!</span> I'm a passionate{" "}
                  <span class="font-bold">Salesforce developer</span> with
                  interests in the <span class="font-bold">stock market</span>{" "}
                  and <span class="font-bold">web development</span>. From
                  notable <span class="italic">Salesforce achievements</span> to{" "}
                  <span class="italic">innovative web projects</span>, my
                  journey is fueled by a commitment to{" "}
                  <span class="italic">excellence</span>.
                </p>
              </section>
              <a
                href="https://1drv.ms/b/s!Am0F9o66OzRlj0YvUrdJaGjiYBop?e=GGBj8v"
                download="Resume-PDF-document"
                target="_blank"
                rel="noreferrer"
              >
                <button className="md:px-6 py-1 px-4 md:py-2   md:flex hidden mb-5 border font-semibold border-black bg-transparent text-black">
                  {" "}
                  Download Resume
                </button>
              </a>
            </div>
          </div>

          <div className=" flex w-full md:w-1/2 md:pt-32 md:pl-32 justify-center  ">
            {/* Timeline */}
            <div className="w-5/6 md:w-full">
              {jobsData.map((job, index) => (
                <div key={index} className="flex">
                  {/* Vertical Line */}
                  <div className="w-1/12 mt-2 flex items-center">
                    <div className="w-1 h-full bg-gray-500"></div>
                  </div>

                  {/* Job Details */}
                  <div className="w-11/12">
                    <div className="mb-6">
                      <div className="flex items-center mb-2">
                        <img
                          src={job.logo}
                          alt="Company Logo"
                          className="w-8 h-8 mr-4"
                        />
                        <div>
                          <p className="text-lg font-semibold">{job.company}</p>
                          <p className="text-sm text-gray-500">
                            {job.position}
                          </p>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500">{`${job.startDate} - ${job.endDate}`}</p>
                      <p className="text-sm">{job.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="md:px-6 py-1 px-4 md:py-2   md:hidden flex mt-5 border font-semibold border-black bg-transparent text-black">
            {" "}
            Download Resume
          </button>
        </div>
      </header>
    );}
export default Header;