import React from 'react';
import BlogCard from './Blogs/BlogCard';
import blogData from './Blogs/blogData';
import svgContent from "./svgContent";
function BlogPage() {
  const featuredBlogs = blogData.filter((blog) => blog.featured);

  return (
    <div className="container bg-transparent mx-auto mt-8">
        <div class="absolute top-0 left-0 w-full h-screen md:h-full" dangerouslySetInnerHTML={{ __html: svgContent }} />
      <h1 className="text-4xl font-bold mb-4 text-center p-10 text-gray-800">Featured Blogs</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {featuredBlogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
      <a href="/bloglist" className="block text-center text-slate-900 mt-4 relative hover:underline">
        See more blogs
      </a>
    </div>
  );
}

export default BlogPage;
