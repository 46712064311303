import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import { db, auth, provider } from "./firebase";

// Your Admin component
function Admin() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      onAuthStateChanged(auth, async (user) => {
        setIsSignedIn(!!user);
        if (user) {
          const q = query(collection(db, "feedback"));
          const querySnapshot = await getDocs(q);
          setIsAdmin(querySnapshot.size > 0);
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(data);
        } else {
          setIsAdmin(false);
        }
      });
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setIsSignedIn(!!user);
      fetchMessages();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsSignedIn(false);
      setIsAdmin(false);
      fetchMessages();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMessage = (message) => {
    const docRef = doc(db, "feedback", message.id);
    deleteDoc(docRef)
      .then(() => {
        console.log("Document successfully deleted!");
        fetchMessages();
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  const currentMessage = messages.find(
    (message) => message.id === selectedMessage?.id
  );

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMessage(null);
  };

  const currentMessages = messages;

  return (
    <div className="max-w-5xl mx-auto p-4 w-screen h-screen">
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <div className="text-primary animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-4xl font-bold text-indigo-700">
              Admin Dashboard
            </h1>
            {isSignedIn ? (
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                onClick={handleSignOut}
              >
                Sign out
              </button>
            ) : (
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                onClick={handleSignIn}
              >
                Sign in with Google
              </button>
            )}
          </div>
          {isError && (
            <div className="bg-red-200 text-red-700 py-2 px-4 mb-4 rounded">
              An error occurred while fetching feedback data.
            </div>
          )}
          {isSignedIn && !isAdmin && (
            <div className="bg-yellow-200 text-yellow-700 py-2 px-4 mb-4 rounded">
              You do not have permission to view feedback data.
            </div>
          )}
          {isSignedIn && isAdmin && (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300 shadow-md">
                <thead>
                  <tr>
                    <th className="py-3 px-6 text-left border-b border-gray-300 bg-gray-100 hidden md:table-cell">
                      Name
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray-300 bg-gray-100 hidden md:table-cell">
                      Email
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray-300 bg-gray-100 hidden md:table-cell">
                      Message
                    </th>
                    <th className="py-3 px-6 text-left border-b border-gray-300 bg-gray-100 hidden md:table-cell">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentMessages.map((message) => (
                    <tr
                      key={message.id}
                      className="border-b border-gray-300 transition-all hover:bg-gray-50"
                    >
                      <td className="py-3 px-6 whitespace-nowrap">
                        {message.name}
                      </td>
                      <td className="py-3 px-6 whitespace-nowrap underline text-blue-600">
                        <a href={"mailto:" + message.email}>{message.email}</a>
                      </td>
                      <td className="py-3 px-6 whitespace-nowrap">
                        <button
                          className="btn btn-link text-indigo-600"
                          onClick={() => handleMessageClick(message)}
                        >
                          View message
                        </button>
                      </td>
                      <td className="py-3 px-6 whitespace-nowrap">
                        <button
                          onClick={() => deleteMessage(message)}
                          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded transition-all"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
      {isModalOpen && (
        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={handleCloseModal}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              ></span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Message from {currentMessage?.name}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {currentMessage?.email}
                    </p>
                    <p className="mt-2 text-gray-600">
                      {currentMessage?.message}
                    </p>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </div>
  );
}

export default Admin;
