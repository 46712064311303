import React from 'react';
import Header from './About/Header';
import Post from './About/Post';
import Certificate from './About/Certificate';
import Event from './About/Event';

const AboutPage = () => {
  

  return (
    <div className="font-sans">
     <Header/>
     <Post/>
     <Certificate/>
     <Event/>

      

    
    </div>
  );
}

export default AboutPage;
