import React, { useState } from "react";

const Certificate = () => {
  const certificates = [
    {
      name: "MERN Stack Specialization",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212186&authkey=%21AN5c5UjE3grdCP0&width=987&height=760",
    },
    {
      name: "Hackerrank problem solving",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%212187&authkey=%21AF_LizX6OJb_NZo&width=1107&height=835",
    },
    {
      name: "Google Digital Marketing",
      image:
        "https://onedrive.live.com/embed?resid=65343BBA8EF6056D%211636&authkey=%21ANQRCCqp38IBlm0&width=720&height=505",
    }, // Add more certificates as needed
  ];

  const [selectedCertificate, setSelectedCertificate] = useState(
    certificates[0]
  );

  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSliderValue(value);
    setSelectedCertificate(certificates[value]);
  };

  return (
    <div className="flex flex-col md:flex-row h-full p-2 md:p-8 bg-red-50">
      <div className="md:w-2/5">
        <h1 className="text-lg md:text-2xl font-bold mb-4 md:mb-6">
          Certificates
        </h1>
        <div className="flex items-center">
          <input
            type="range"
            min="0"
            max={certificates.length - 1}
            value={sliderValue}
            onChange={handleSliderChange}
            className="transform rotate-90 w-16 md:w-24"
          />
          <div className="md:inline ml-4 space-x-2">
            {certificates.map((certificate, index) => (
              <div
                key={index}
                className={`cursor-pointer p-2 ${
                  sliderValue === index ? "font-bold" : "hover:gray-200"
                }`}
                onClick={() => {
                  setSliderValue(index);
                  setSelectedCertificate(certificates[index]);
                }}
              >
                {certificate.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="md:w-3/5 p-3 md:p-8">
        <img
          className="w-40 h-20 md:w-full md:h-auto border rounded mt-4"
          src={selectedCertificate.image}
          alt={selectedCertificate.name}
        />
      </div>
    </div>
  );
};

export default Certificate;
